import { Box, Typography, Grid, Pagination } from "@mui/material";
import { useState } from "react";
import { useGetData } from "../../hooks";
import { NavLink } from "react-router-dom";
import { extractDescription } from "../../utils";
import { handleGAEvents } from "../../utils/handleGAEvents";
import { gaConstants } from "../../constants/gaConstants";
import "../style.css";
import { formatDate } from "../../utils/formatDate";



const NewsFeed = () => {
    const itemsPerPage = 5;
    const [page, setPage] = useState(1);
    const { loading, data, error } = useGetData("/baylor/football/news-feed/");
    if (loading) {
        return "Loading...";
    }

    if (error) {
        return JSON.stringify(error);
    }
    const {
        rss: {
            channel: { item },
        },
    } = data;

    const handleChangePage = (event, value) => {
        setPage(value);
        handleGAEvents(gaConstants.newsPagination)
    };

    const startIndex = (page - 1) * itemsPerPage;
    const paginatedData = item.slice(startIndex, startIndex + itemsPerPage);

    return (
        <Box
            sx={{
                border: "1px solid #343434",
                background: "#262626",
                margin: "35px 35px 20px",
                color: "#FFFFFF",
                borderRadius: '6px',
                padding: "40px 50px",
            }}
            className="latest-news">
            <Typography variant="h5" sx={{ textAlign: "left", mb: 2, pb: 2, fontStyle: " italic", fontWeight: '700' }}>
                LATEST BAYLOR FOOTBALL NEWS
            </Typography>
            <Grid container className="row">

                {paginatedData.map((item, index) => {
                    const { title, pubDate, link, description } = item;
                    let imageUrl = "images/dummy-image.png"
                    if (item["media:content"] && item["media:content"]["@url"]) {
                        imageUrl = item["media:content"]["@url"]
                    }
                    const createdBy = item["dc:creator"];
                    return (
                        <Box item className="grid-column" key={index}>
                            <Box
                                sx={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <img alt={title} src={imageUrl} loading="lazy" style={{ borderRadius: "10px", border: '1px solid #fff', minHeight: "250px" }} />
                                <br />
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                    as={NavLink}
                                    to={link}
                                    target="_blank"
                                    sx={{ textDecoration: "none", cursor: "pointer", color: "#FFFFFF", lineHeight: 1.3, fontStyle: " italic", fontSize: "20px", fontWeight: "700" }}
                                >
                                    {title}
                                </Typography>
                                <Typography variant="body2" style={{ paddingTop: "20px", display: '-webkit-box', WebkitLineClamp: 3, overflow: 'hidden', WebkitBoxOrient: 'vertical', }}>
                                    {extractDescription(description)}
                                </Typography>
                                <Box className="created-wrapper">
                                    <Typography className="created-by" component={'p'}>
                                        {createdBy}
                                    </Typography>
                                    <Typography className="pub-date" component={'p'}>
                                        {formatDate(pubDate)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    );
                })}
            </Grid>
            <Box mt={2} sx={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                    className="paginations"
                    count={Math.ceil(item.length / itemsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                />
            </Box>

        </Box>
    );
};

export default NewsFeed;