const FAQ = [
  "When does the Baylor Football 2024 season start?",
  "Where can I find the Baylor Football 2024 game schedule?",
  "How can I purchase tickets for Baylor Football games?",
  "What are the key matchups for Baylor Football in 2024?",
  "Who are the standout players on the Baylor Bears roster in 2024?",
  "Where can I watch Baylor Football games online or on TV?",
  "What are the parking options for Baylor Football home games?",
  "How can I join the Baylor Football fan club or booster club?",
  "What are the latest recruiting updates for Baylor Football?",
  "How can I get to Baylor Stadium using public transportation?",
  "What are the tailgating rules for Baylor Football games?",
  "Where can I buy official Baylor Football merchandise?",
  "What are the safety measures in place at Baylor Stadium?",
  "Can I bring food and drinks into the stadium during Baylor games?",
  "How can I stay updated with live scores and highlights from Baylor Football?",
  "What are the best places to stay in Waco for a Baylor game weekend?",
  "How can I volunteer or work at Baylor Football games?",
  "What are the policies for bringing cameras and recording devices to the games?",
  "Where can I find stats and player profiles for Baylor Football?",
  "What are the plans for Baylor Football's homecoming game in 2024?",
];


export const getRandomPrompts = () => {
    let shuffled = FAQ.sort(() => 0.5 - Math.random());
    // Get sub-array of first n elements after shuffled
    return shuffled.slice(0, 4);
}
