import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Typography from "@mui/material/Typography";
import "../style.css";

const mockData = [
  {
    image: "images/sliderImages/1.jpg",
    title: "Baylor's 2024 Football Season: New Faces, Big Games, and High Hopes",
    content_text:
      " Baylor Football looks to rebound in 2024 with a stronger roster and a competitive Big 12 schedule. Key matchups, star players, and coaching changes shape the season.",
  },
  {
    image: "images/sliderImages/2.jpg",
    title: "Best and Worst-Case Scenarios for Baylor Football in the 2024 Season",
    content_text:
      "Explore the best and worst-case scenarios for Baylor Football in 2024. Can Coach Aranda lead the Bears to success, or will challenges hold them back?",
  },
  {
    image: "images/sliderImages/3.jpg",
    title: "Baylor Football Prepares for a Tough 2024 Season: Players to Watch",
    content_text:
      "Baylor Football looks to rebound in 2024 with a stronger roster and a competitive Big 12 schedule. Key matchups, star players, and coaching changes shape the season.",
  },
  {
    image: "images/sliderImages/4.jpg",
    title: "Can Baylor Football Compete for a Big 12 Title in 2024?",
    content_text:
      "Preview the 2024 Baylor Football season as the Bears look to rebound from 2023. Can Coach Aranda lead the team to success in the Big 12, or will challenges hold them back?",
  },
];
const Slider = () => {
  return (
    <div className="header-top-wrapper">
      {
        <Carousel
          showArrows={true}
          autoPlay
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          stopOnHover={true}
          dots={false}
          className="carousel-wrapper"
        >
          {mockData.map((sData) => {
            const { title, image, content_text } = sData;
            return (
              <div className="imageWrapper">
                <img src={image} alt={title} />
                <div className="bannerContent">
                  <Typography variant="h1" component="h1">
                    {title}
                  </Typography>
                  <Typography variant="p" component="p">
                    {content_text}
                  </Typography>
                </div>
              </div>
            );
          })}
        </Carousel>

      }
    </div>
  );
};

export default Slider;
