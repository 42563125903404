import {
  Box,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useMemo } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import { handleGAEvents } from "../../utils/handleGAEvents";
import { gaConstants } from "../../constants/gaConstants";
import { getRandomPrompts } from "./constant";

const SearchArea = () => {
  const [question, setQuestion] = useState("");
  const navigate = useNavigate();
  const handleSearch = () => {
    if (question.length > 0) {
      handleGAEvents(gaConstants.search);
      navigate("/result", { state: { question } });
    }
  };

  const handleArrowClick = (prompt) => {
    handleGAEvents(gaConstants.suggestionBoxArrows);
    setQuestion(prompt);
  };
  const prompts = useMemo(getRandomPrompts, []);
  return (
    <Box className="search-suggestion-wrapper">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: "20px",
          padding: "0 60px 0 60px",
          marginTop: "50px",
          gap: "20px",
        }}
        className="input-wrapper"
      >
        <TextField
          style={{ background: "#D9D9D9", borderRadius: "10px" }}
          fullWidth
          variant="outlined"
          placeholder="Ask a question about Baylor Football 2024. Powered by AI to enhance your fan experience."
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
        <IconButton
          aria-label="search"
          sx={{
            backgroundColor: "#f1950e",
            color: "#000",
            borderRadius: "5px",
            padding: "15px",
            border: "1px solid #fff",
            "&:hover": {
              backgroundColor: "#f1950e",
              color: "#fff",
            },
          }}
          onClick={handleSearch}
        >
          <SearchIcon />
        </IconButton>
      </Box>
      <Box className="suggestion-wrapper" style={{ padding: "0 60px 0 60px" }}>
        <Grid container spacing={3} sx={{ mt: 2 }}>
          {prompts.map((prompt) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={prompt}
              className="grid-wrapper"
            >
              <Paper
                className="suggestion-box"
                variant="outlined"
                sx={{
                  p: 2,
                  borderRadius: "9px",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                  height: "100%",
                  position: "relative",
                  background: "#161515",
                  border: "1px solid #444343",
                }}
                onClick={() => handleArrowClick(prompt)}
              >
                <svg
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  xmlns="http://www.w3.org/2000/svg"
                  className="star"
                >
                  <path
                    d="M12.6751 20.5427L11.625 24.2187L10.5749 20.5427C10.3035 19.5931 9.79458 18.7284 9.09625 18.03C8.39792 17.3317 7.53318 16.8228 6.58363 16.5514L2.90625 15.5L6.58233 14.4499C7.53189 14.1785 8.39663 13.6696 9.09496 12.9713C9.79329 12.2729 10.3022 11.4082 10.5736 10.4586L11.625 6.78125L12.6751 10.4573C12.9465 11.4069 13.4554 12.2716 14.1537 12.97C14.8521 13.6683 15.7168 14.1772 16.6664 14.4486L20.3437 15.5L16.6677 16.5501C15.7181 16.8215 14.8534 17.3304 14.155 18.0287C13.4567 18.7271 12.9478 19.5918 12.6764 20.5414L12.6751 20.5427ZM23.5845 11.2569L23.25 12.5937L22.9155 11.2569C22.724 10.4904 22.3278 9.79034 21.7692 9.23158C21.2107 8.67282 20.5108 8.27634 19.7444 8.08454L18.4062 7.75L19.7444 7.41546C20.5108 7.22366 21.2107 6.82718 21.7692 6.26842C22.3278 5.70966 22.724 5.00961 22.9155 4.24313L23.25 2.90625L23.5845 4.24313C23.7761 5.00977 24.1725 5.70993 24.7313 6.2687C25.2901 6.82748 25.9902 7.22386 26.7569 7.41546L28.0937 7.75L26.7569 8.08454C25.9902 8.27614 25.2901 8.67252 24.7313 9.2313C24.1725 9.79007 23.7761 10.4902 23.5845 11.2569ZM21.8214 26.5657L21.3125 28.0937L20.8036 26.5657C20.6609 26.1377 20.4205 25.7488 20.1015 25.4297C19.7825 25.1107 19.3936 24.8703 18.9655 24.7277L17.4375 24.2187L18.9655 23.7098C19.3936 23.5672 19.7825 23.3268 20.1015 23.0078C20.4205 22.6887 20.6609 22.2998 20.8036 21.8718L21.3125 20.3437L21.8214 21.8718C21.9641 22.2998 22.2045 22.6887 22.5235 23.0078C22.8425 23.3268 23.2314 23.5672 23.6595 23.7098L25.1875 24.2187L23.6595 24.7277C23.2314 24.8703 22.8425 25.1107 22.5235 25.4297C22.2045 25.7488 21.9641 26.1377 21.8214 26.5657Z"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <Typography
                  sx={{ fontSize: "15px", mt: "20px", color: "#FFFFFF" }}
                  variant="body1"
                  gutterBottom
                >
                  {prompt}
                </Typography>
                <Box sx={{ textAlign: "right" }}>
                  <KeyboardArrowRightIcon
                    sx={{ fontSize: "20px", fill: "#F0950C" }}
                    onClick={() => handleArrowClick(prompt)}
                  />
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SearchArea;
